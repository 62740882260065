/*@font-face {*/
/*    font-family: "TheNautigal";*/
/*    src: url("../../asset/fonts/TheNautigal-Regular.ttf") format("truetype");*/
/*}*/

/*@font-face {*/
/*    font-family: "EBGaramond";*/
/*    src: url("../../asset/fonts/EBGaramond-Medium.ttf") format("truetype");*/
/*}*/

body {
    background-color: #2b313a;
    margin: 0;
    overflow-y: hidden;
    overflow-x: hidden;
}

body::-webkit-scrollbar {
    display: none;
}

h1 {
    white-space: nowrap;
}

p {
    /*font-family: EBGaramond, sans-serif;*/
    font-family: "EB Garamond", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.desktop {
    display: none;
}

.mobile {
    display: block;
}

.header {
    /*font-family: TheNautigal, sans-serif;*/
    font-family: "The Nautigal", cursive;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    background: -webkit-linear-gradient(#ebd19e, #e4b75e, #656564);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 30px;
    letter-spacing: 5px;
}

.flip {
    transform: scaleX(-1);
}

.map {
    width: 350px;
    height: 250px;
}

section {
    position: relative;
}

section .background {
    width: 40%;
    opacity: 0.5;
}

.background {
    z-index: -1;
}

.background-right {
    position: absolute;
    right: 0;
}

.background-left {
    position: absolute;
    left: 0;
}

.loading {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: #2b313a;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading.hide {
    transform: translateY(-200%);
    transition: all 1000ms linear;
}

.loading .background {
    position: absolute;
    bottom: -60px;
    width: 250px;
}

.loading .logo {
    opacity: 1;
    width: 120px;
}

.loading .logo.hide {
    opacity: 0;
    transition: all 1000ms linear;
}

.loading .header {
    font-size: 50px;
    white-space: normal;
    padding: 0 20px 0 20px;
}

.loading .question {
    display: none;
    text-align: center;
    transform: translateY(-200%);
}

.loading .question.show {
    transform: translateY(0);
    transition: all 1000ms linear;
}

.loading .question .text-big {
    color: white;
    font-size: 18px;
}

.loading .question .text-small {
    color: white;
    text-align: left;
    font-size: 12px;
    padding: 0 40px 0 40px;
}

.loading .question .option {
    display: flex;
}

.loading .question .button {
    width: 50%;
    flex-grow: 1;
}

.loading .question button {
    background-color: #04AA6D;
    border: none;
    color: white;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 20px;
}

.loading .question button.disabled {
    background-color: gray;
    pointer-events: none;
    cursor: not-allowed;
}

.loading .question button.selected {
    border: white 2px solid;
}

.loading .question .select {
    width: 120px;
}

.loading .question .number {
    width: 60px;
}

.loading .question .confirm {
    width: 120px;
}

.section-1 {
    padding-top: 50px;
}

.section-1 .background {
    top: 60px;
}

.section-1 .content {
    display: flex;
    padding-top: 20px;
    width: 100%;
}

.section-1 .content .child-1 {
    text-align: right;
    width: 55%;
    padding: 35px 0 0 0;
}

.section-1 .content .child-1 h1 {
    margin: 0;
    /*font-family: TheNautigal, sans-serif;*/
    font-family: "The Nautigal", cursive;
    font-weight: 400;
    font-style: normal;
    background: -webkit-linear-gradient(#ebd19e, #e4b75e, #656564);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 40px;
}

.section-1 .content .child-2 {
    width: 45%;
}

.section-1 .content .child-2 img {
    width: 150px;
}

.section-2 {
    margin-top: 100px;
}

.section-2 p {
    text-align: center;
    color: white;
    font-size: 14px;
    padding: 0 10px 0 10px;
}

.section-2 .count-time {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 50px;
}

.section-2 .count-time .child-first {
    position: relative;
    text-align: center;
    color: white;
}

.section-2 .count-time .child-first img {
    display: block;
    width: 70px;
}

.section-2 .count-time .child-first .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
}

.section-2 .count-time .child-second {
    position: relative;
    width: 10%;
}

.section-2 .count-time .child-second img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
}

.section-3 {
    margin-top: 125px;
}

.section-3 .background-top {
    top: -150px;
}

.section-3 .background-bottom {
    bottom: -240px;
}

.section-3 .child {
    display: flex;
    width: 100%;
}

.section-3 .child .right {
    text-align: right;
}

.section-3 .child .left {
    text-align: left;
}

.section-3 .child .image {
    width: 40%;
}

.section-3 .child .image img {
    border-radius: 150px;
    border: yellow 1px solid;
    width: 120px;
}

.section-3 .child .content {
    width: 60%;
    margin: 15px;
}

.section-3 .child .content h1 {
    margin: 0;
    background: -webkit-linear-gradient(#ebd19e, #e4b75e, #656564);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 24px;
}

.section-3 .child .content p {
    margin: 0;
    color: white;
    font-size: 14px;
}

.section-3 .line {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.section-3 .line img {
    width: 250px;
}

.section-4 {
    margin-top: 125px;
    text-align: -webkit-center;
}

.section-4 .link {
    text-decoration: underline;
    margin-top: 50px;
}

.section-4 .link a {
    color: white;
    position: relative;
    z-index: 1;
}

.section-4 p {
    color: white;
    font-size: 15px;
    margin-top: 0;
    padding: 0 50px 0 50px;
}

.section-4 .background {
    bottom: -200px;
}

.section-4 .only-image {
    width: auto;
}

.section-4 .image {
    padding: 15px 8px 60px 8px;
    background: white;
    width: fit-content;
    border: 3px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to bottom, #ebd19e, #e4b75e, #656564);
}

.section-4 .image img {
    width: 320px;
    /*border: #00dfd3 3px solid;*/
}

.section-4 .child-image {
    position: absolute;
}

.section-4 .child-image-1 {
    transform: scale(0.2) rotateZ(30deg);
    top: -50px;
    left: 200px;
}

.section-4 .child-image-2 {
    transform: scale(0.15) rotateZ(-20deg);
    top: 150px;
    left: 200px;
}

.section-4 .child-image-3 {
    transform: scale(0.15) rotateZ(10deg);
    top: 400px;
    left: 200px;
}

.section-4 .child-image-4 {
    transform: scale(0.13) rotateZ(-15deg);
    top: 700px;
    left: 200px;
}

.section-4 .child-image-5 {
    transform: scale(0.13) rotateZ(15deg);
    top: 700px;
    left: 400px;
}

.section-4 .child-image-6 {
    transform: scale(0.13) rotateZ(-20deg);
    top: 700px;
    right: 600px;
}

.section-4 .child-image-7 {
    transform: scale(0.13) rotateZ(10deg);
    top: 700px;
    right: 300px;
}

.section-4 .child-image-8 {
    transform: scale(0.25) rotateZ(-10deg);
    top: 400px;
    right: 200px;
}

.section-4 .child-image-9 {
    transform: scale(0.15) rotateZ(15deg);
    top: 200px;
    right: 250px;
}

.section-4 .child-image-10 {
    transform: scale(0.13) rotateZ(8deg);
    top: 50px;
    right: 300px;
}

.section-4 .child-image-11 {
    transform: scale(0.14) rotateZ(-15deg);
    top: -130px;
    right: 190px;
}

.section-5 {
    margin-top: 150px;
    text-align: center;
}

.section-5 .wedding-place {
    border: 3px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to bottom, #ebd19e, #e4b75e, #656564);
    width: 350px;
}

.section-5 p {
    color: white;
}

.section-5 .text-big {
    font-size: 20px;
    margin: 10px 0 10px 0;
}

.section-5 .text-small {
    font-size: 13px;
}

.section-6 {
    margin-top: 80px;
}

.section-6 .content {
    width: 100%;
    display: flex;
    text-align: center;
}

.section-6 .content h1 {
    /*height: 80px;*/
    white-space: normal;
}

.section-6 .content p {
    color: white;
    font-size: 15px;
}

.section-6 .content img {
    border-radius: 30px;
    width: 150px;
}

.section-6 .content .child-left {
    width: 100%;
}

.section-6 .content .child-right {
    width: 100%;
}

.section-7 {
    margin-top: 50px;
}

.section-7 .background-top {
    top: -50px;
}

.section-7 .content {
    width: 60%;
    margin-left: 10px;
}

.section-7 .content h1 {
    margin: 0;
    font-size: 35px;
}

@media screen and (min-width: 769px) {
    .desktop {
        display: block;
    }

    .mobile {
        display: none;
    }

    .header {
        font-size: 70px;
        letter-spacing: 10px;
    }

    section .background {
        width: auto;
    }

    .loading .background {
        width: auto;
    }

    .loading .logo {
        width: 140px;
    }

    .loading .question .text-big {
        font-size: 25px;
    }

    .loading .question .text-small {
        font-size: 15px;
        padding: 0;
    }

    .section-1 .background {
        top: 20px;
    }

    .section-1 .content .child-1 {
        width: 50%;
        padding: 50px 100px 0 0;
    }

    .section-1 .content .child-1 h1 {
        font-size: 115px;
    }

    .section-1 .content .child-2 {
        width: 50%;
    }

    .section-1 .content .child-2 img {
        width: 400px;
    }

    .section-2 {
        margin-top: 200px;
    }

    .section-2 p {
        font-size: 25px;
        padding: 0;
    }

    .section-2 .count-time {
        margin-top: 100px;
    }

    .section-2 .count-time .child-first img {
        width: 200px;
    }

    .section-2 .count-time .child-first .text {
        font-size: 80px;
    }

    .section-2 .count-time .child-second img {
        width: 50px;
    }

    .section-3 .child .image img {
        width: 300px;
    }

    .section-3 .child .content {
        margin: 40px;
    }

    .section-3 .child .content h1 {
        font-size: 50px;
    }

    .section-3 .child .content p {
        font-size: 20px;
    }

    .section-3 .line img {
        width: 600px;
    }

    .section-3 .background-bottom {
        bottom: -150px;
    }

    .section-4 .image img {
        width: 400px;
    }

    .section-4 .link {
        margin-top: -250px;
    }

    .section-4 p {
        font-size: 20px;
        margin-top: 250px;
        padding: 0;
    }

    .section-4 .background {
        bottom: -130px;
    }

    .section-5 .text-big {
        font-size: 30px;
        margin: 0;
    }

    .section-5 .text-small {
        font-size: 20px;
    }

    .section-5 .wedding-place {
        width: 1000px;
    }

    .map {
        width: 1000px;
        height: 500px;
    }

    .section-6 .content h1 {
        height: auto;
        white-space: normal;
    }

    .section-6 .content p {
        font-size: 20px;
    }

    .section-6 .content img {
        width: 500px;
    }

    .section-7 {
        margin-top: 150px;
    }

    .section-7 .content h1 {
        font-size: 120px;
    }

    .section-7 .background-top {
        top: -150px;
    }
}