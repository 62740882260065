body {
  background-color: #2b313a;
  margin: 0;
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

h1 {
  white-space: nowrap;
}

p {
  font-optical-sizing: auto;
  font-family: EB Garamond, serif;
  font-style: normal;
  font-weight: 400;
}

.desktop {
  display: none;
}

.mobile {
  display: block;
}

.header {
  text-align: center;
  -webkit-text-fill-color: transparent;
  letter-spacing: 5px;
  background: -webkit-linear-gradient(#ebd19e, #e4b75e, #656564);
  -webkit-background-clip: text;
  font-family: The Nautigal, cursive;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
}

.flip {
  transform: scaleX(-1);
}

.map {
  width: 350px;
  height: 250px;
}

section {
  position: relative;
}

section .background {
  opacity: .5;
  width: 40%;
}

.background {
  z-index: -1;
}

.background-right {
  position: absolute;
  right: 0;
}

.background-left {
  position: absolute;
  left: 0;
}

.loading {
  z-index: 10;
  background-color: #2b313a;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
}

.loading.hide {
  transition: all 1s linear;
  transform: translateY(-200%);
}

.loading .background {
  width: 250px;
  position: absolute;
  bottom: -60px;
}

.loading .logo {
  opacity: 1;
  width: 120px;
}

.loading .logo.hide {
  opacity: 0;
  transition: all 1s linear;
}

.loading .header {
  white-space: normal;
  padding: 0 20px;
  font-size: 50px;
}

.loading .question {
  text-align: center;
  display: none;
  transform: translateY(-200%);
}

.loading .question.show {
  transition: all 1s linear;
  transform: translateY(0);
}

.loading .question .text-big {
  color: #fff;
  font-size: 18px;
}

.loading .question .text-small {
  color: #fff;
  text-align: left;
  padding: 0 40px;
  font-size: 12px;
}

.loading .question .option {
  display: flex;
}

.loading .question .button {
  flex-grow: 1;
  width: 50%;
}

.loading .question button {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #04aa6d;
  border: none;
  border-radius: 20px;
  margin: 4px 2px;
  padding: 15px;
  font-size: 16px;
  text-decoration: none;
  display: inline-block;
}

.loading .question button.disabled {
  pointer-events: none;
  cursor: not-allowed;
  background-color: gray;
}

.loading .question button.selected {
  border: 2px solid #fff;
}

.loading .question .select {
  width: 120px;
}

.loading .question .number {
  width: 60px;
}

.loading .question .confirm {
  width: 120px;
}

.section-1 {
  padding-top: 50px;
}

.section-1 .background {
  top: 60px;
}

.section-1 .content {
  width: 100%;
  padding-top: 20px;
  display: flex;
}

.section-1 .content .child-1 {
  text-align: right;
  width: 55%;
  padding: 35px 0 0;
}

.section-1 .content .child-1 h1 {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(#ebd19e, #e4b75e, #656564);
  -webkit-background-clip: text;
  margin: 0;
  font-family: The Nautigal, cursive;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}

.section-1 .content .child-2 {
  width: 45%;
}

.section-1 .content .child-2 img {
  width: 150px;
}

.section-2 {
  margin-top: 100px;
}

.section-2 p {
  text-align: center;
  color: #fff;
  padding: 0 10px;
  font-size: 14px;
}

.section-2 .count-time {
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  display: flex;
}

.section-2 .count-time .child-first {
  text-align: center;
  color: #fff;
  position: relative;
}

.section-2 .count-time .child-first img {
  width: 70px;
  display: block;
}

.section-2 .count-time .child-first .text {
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section-2 .count-time .child-second {
  width: 10%;
  position: relative;
}

.section-2 .count-time .child-second img {
  width: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section-3 {
  margin-top: 125px;
}

.section-3 .background-top {
  top: -150px;
}

.section-3 .background-bottom {
  bottom: -240px;
}

.section-3 .child {
  width: 100%;
  display: flex;
}

.section-3 .child .right {
  text-align: right;
}

.section-3 .child .left {
  text-align: left;
}

.section-3 .child .image {
  width: 40%;
}

.section-3 .child .image img {
  border: 1px solid #ff0;
  border-radius: 150px;
  width: 120px;
}

.section-3 .child .content {
  width: 60%;
  margin: 15px;
}

.section-3 .child .content h1 {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(#ebd19e, #e4b75e, #656564);
  -webkit-background-clip: text;
  margin: 0;
  font-size: 24px;
}

.section-3 .child .content p {
  color: #fff;
  margin: 0;
  font-size: 14px;
}

.section-3 .line {
  text-align: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.section-3 .line img {
  width: 250px;
}

.section-4 {
  text-align: -webkit-center;
  margin-top: 125px;
}

.section-4 .link {
  margin-top: 50px;
  text-decoration: underline;
}

.section-4 .link a {
  color: #fff;
  z-index: 1;
  position: relative;
}

.section-4 p {
  color: #fff;
  margin-top: 0;
  padding: 0 50px;
  font-size: 15px;
}

.section-4 .background {
  bottom: -200px;
}

.section-4 .only-image {
  width: auto;
}

.section-4 .image {
  border: 3px solid;
  background: #fff;
  border-image-source: linear-gradient(#ebd19e, #e4b75e, #656564);
  border-image-slice: 1;
  width: fit-content;
  padding: 15px 8px 60px;
}

.section-4 .image img {
  width: 320px;
}

.section-4 .child-image {
  position: absolute;
}

.section-4 .child-image-1 {
  top: -50px;
  left: 200px;
  transform: scale(.2)rotateZ(30deg);
}

.section-4 .child-image-2 {
  top: 150px;
  left: 200px;
  transform: scale(.15)rotateZ(-20deg);
}

.section-4 .child-image-3 {
  top: 400px;
  left: 200px;
  transform: scale(.15)rotateZ(10deg);
}

.section-4 .child-image-4 {
  top: 700px;
  left: 200px;
  transform: scale(.13)rotateZ(-15deg);
}

.section-4 .child-image-5 {
  top: 700px;
  left: 400px;
  transform: scale(.13)rotateZ(15deg);
}

.section-4 .child-image-6 {
  top: 700px;
  right: 600px;
  transform: scale(.13)rotateZ(-20deg);
}

.section-4 .child-image-7 {
  top: 700px;
  right: 300px;
  transform: scale(.13)rotateZ(10deg);
}

.section-4 .child-image-8 {
  top: 400px;
  right: 200px;
  transform: scale(.25)rotateZ(-10deg);
}

.section-4 .child-image-9 {
  top: 200px;
  right: 250px;
  transform: scale(.15)rotateZ(15deg);
}

.section-4 .child-image-10 {
  top: 50px;
  right: 300px;
  transform: scale(.13)rotateZ(8deg);
}

.section-4 .child-image-11 {
  top: -130px;
  right: 190px;
  transform: scale(.14)rotateZ(-15deg);
}

.section-5 {
  text-align: center;
  margin-top: 150px;
}

.section-5 .wedding-place {
  border: 3px solid;
  border-image-source: linear-gradient(#ebd19e, #e4b75e, #656564);
  border-image-slice: 1;
  width: 350px;
}

.section-5 p {
  color: #fff;
}

.section-5 .text-big {
  margin: 10px 0;
  font-size: 20px;
}

.section-5 .text-small {
  font-size: 13px;
}

.section-6 {
  margin-top: 80px;
}

.section-6 .content {
  text-align: center;
  width: 100%;
  display: flex;
}

.section-6 .content h1 {
  white-space: normal;
}

.section-6 .content p {
  color: #fff;
  font-size: 15px;
}

.section-6 .content img {
  border-radius: 30px;
  width: 150px;
}

.section-6 .content .child-left, .section-6 .content .child-right {
  width: 100%;
}

.section-7 {
  margin-top: 50px;
}

.section-7 .background-top {
  top: -50px;
}

.section-7 .content {
  width: 60%;
  margin-left: 10px;
}

.section-7 .content h1 {
  margin: 0;
  font-size: 35px;
}

@media screen and (width >= 769px) {
  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }

  .header {
    letter-spacing: 10px;
    font-size: 70px;
  }

  section .background, .loading .background {
    width: auto;
  }

  .loading .logo {
    width: 140px;
  }

  .loading .question .text-big {
    font-size: 25px;
  }

  .loading .question .text-small {
    padding: 0;
    font-size: 15px;
  }

  .section-1 .background {
    top: 20px;
  }

  .section-1 .content .child-1 {
    width: 50%;
    padding: 50px 100px 0 0;
  }

  .section-1 .content .child-1 h1 {
    font-size: 115px;
  }

  .section-1 .content .child-2 {
    width: 50%;
  }

  .section-1 .content .child-2 img {
    width: 400px;
  }

  .section-2 {
    margin-top: 200px;
  }

  .section-2 p {
    padding: 0;
    font-size: 25px;
  }

  .section-2 .count-time {
    margin-top: 100px;
  }

  .section-2 .count-time .child-first img {
    width: 200px;
  }

  .section-2 .count-time .child-first .text {
    font-size: 80px;
  }

  .section-2 .count-time .child-second img {
    width: 50px;
  }

  .section-3 .child .image img {
    width: 300px;
  }

  .section-3 .child .content {
    margin: 40px;
  }

  .section-3 .child .content h1 {
    font-size: 50px;
  }

  .section-3 .child .content p {
    font-size: 20px;
  }

  .section-3 .line img {
    width: 600px;
  }

  .section-3 .background-bottom {
    bottom: -150px;
  }

  .section-4 .image img {
    width: 400px;
  }

  .section-4 .link {
    margin-top: -250px;
  }

  .section-4 p {
    margin-top: 250px;
    padding: 0;
    font-size: 20px;
  }

  .section-4 .background {
    bottom: -130px;
  }

  .section-5 .text-big {
    margin: 0;
    font-size: 30px;
  }

  .section-5 .text-small {
    font-size: 20px;
  }

  .section-5 .wedding-place {
    width: 1000px;
  }

  .map {
    width: 1000px;
    height: 500px;
  }

  .section-6 .content h1 {
    white-space: normal;
    height: auto;
  }

  .section-6 .content p {
    font-size: 20px;
  }

  .section-6 .content img {
    width: 500px;
  }

  .section-7 {
    margin-top: 150px;
  }

  .section-7 .content h1 {
    font-size: 120px;
  }

  .section-7 .background-top {
    top: -150px;
  }
}
/*# sourceMappingURL=index.93904f32.css.map */
